.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
  margin: 24px 0;

  .title {
    color: white;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-bottom: 16px;
  }

  .subtitle {
    color: rgb(174, 174, 174);
    font-size: 18px;
  }
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 20%);
  background: rgba(255, 255, 255, 5%);
}

.asset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 4px;
}

.position {
  display: flex;
  flex-flow: column;
  padding: 12px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 20%);
  background: rgba(255, 255, 255, 5%);

  .name {
    display: flex;
    flex-direction: column;

    .symbol {
      color: white;
      font-size: 14px;
      letter-spacing: 1.25px;
      margin-bottom: -4px;
    }

    .strategy {
      color: rgb(200, 200, 200);
      font-size: 11px;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    color: #b8b6cb;
    font-size: 12px;
    font-weight: 300;

    .line {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 16px;
  }
}

.lxpl-banner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(95.86deg, #0e0e0f 14.55%, rgba(62, 65, 117, 50%) 37.87%, rgba(190, 90, 77, 50%) 68.39%, #505050 114.9%);
  border-radius: 8px;
  padding: 8px;
  color: white;
  font-size: 11px;

  @media (min-width: 960px) {
    padding: 12px 20px;
    font-size: 12px;
  }
}

button:disabled,
button[disabled] {
  color: #ccc;
  cursor: not-allowed;
}
